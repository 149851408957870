import React from 'react';
import { NavLink } from 'react-router-dom';
import { ROUTER } from '../../config';
import ContantContainerMain from '../../total/ContantContainerMain';
import s from './Land.module.css';
import DocumentContainerDownload from '../../Components/DocumentContainerDownload';

import LinkContainer from '../../Components/LinkContainer';

const Land = (props) => {
   return (
      <div>
         <ContantContainerMain>
            <div className={`mt80 breadcrumbs`}>
               <NavLink to={ROUTER.activity.main} className='breadcrumbsFrom'>Деятельность</NavLink>
               <span className='breadcrumbsTo'> / Земельные участки многодетным семьям</span>
            </div>
            <div className={`mt24 pageTitle`}>
               <p>Земельные участки</p>
               многодетным семьям
            </div>

            <div className={`mt80 borderMain ${s.linkContainer}`}>
               <LinkContainer link='http://old.admhimki.ru/okrug/deyatelnost/zemelnye-uchastki-mnogodetnym-semyam/normativno-pravovye-akty/' text={'Нормативные правовые акты'} />
               <div className="mt24"><LinkContainer link='http://old.admhimki.ru/okrug/deyatelnost/zemelnye-uchastki-mnogodetnym-semyam/reestr-mnogodetnyh-semej/' text={'Реестр многодетных семей'} /></div>
            </div>

            <div className={`mt80 text`}>
               <div className="pageSubtitle">Уважаемые многодетные семьи г.о. Химки!</div>
               <p>С 9 августа 2024 года  в соответствии со <a href="http://pravo.gov.ru/proxy/ips/?docbody=&prevDoc=112034313&backlink=1&&nd=112019399" target="_blank" >статьей 3.1 Закона Московской области от 01.06.2011 № 73/2011-ОЗ «О бесплатном предоставлении земельных участков многодетным семьям в Московской области» (далее – Закон от 01.06.2011 № 73/2011-ОЗ)</a> многодетной семье, имеющей право на бесплатное предоставление земельного участка в соответствии с <a href="http://pravo.gov.ru/proxy/ips/?docbody=&prevDoc=112034313&backlink=1&&nd=112019399" target="_blank">частью 2.1 статьи 3 Закона от 01.06.2011 № 73/2011-ОЗ</a>, по ее заявлению и с ее согласия взамен предоставления такой семье земельного участка в собственность бесплатно однократно предоставляется мера социальной поддержки в виде единовременной денежной выплаты в размере 400 тыс. руб. (далее – выплата взамен земельного участка).</p>
               <p>Порядок предоставления выплаты взамен земельного участка утвержден <a href="http://publication.pravo.gov.ru/document/5000202408070011" target="_blank">постановлением Правительства Московской области от 07.08.2024 № 825-ПП.</a></p>
               <p>Для предоставления выплаты заявитель вправе обратиться в территориальное структурное подразделение Министерства социального развития Московской области путём направления заявления о предоставлении выплаты (Окружное управление социального развития) в форме электронного документа, подписанного простой электронной подписью, с использованием государственной информационной системы Московской области <a href="https://uslugi.mosreg.ru/services/22891" target="_blank">«Портал государственных и муниципальных услуг (функций) Московской области».</a></p>
               <p>
                  Напомним, в Химках к специалистам Окружного управления социального развития можно обратиться по адресу: <br />
                  Юбилейный пр-т, д. 67А (центр «Мои документы», окна 23 и 24). <br />
                  График приёма: понедельник-суббота, с 8:00 до 20:00.
               </p>
               <br />
               <div className="additionalInformation">Дата публикации: 01.11.2024</div>
            </div>

            <div className={`mt80 text`}>
               <p>
                  В соответствии со статьей 3 <a href="http://pravo.gov.ru/proxy/ips/?docbody=&prevDoc=112034313&backlink=1&&nd=112019399" target="_blank">Закона Московской области от 01.06.2011 №73/2011-ОЗ «О бесплатном предоставлении земельных участков многодетным семьям Московской области»</a> право на бесплатное предоставление земельных участков имеют многодетные семьи, которые отвечают одновременно следующим условиям на дату подачи заявления:
               </p>

               <ul className='mt38'>
                  <li>
                     члены многодетной семьи являются гражданами Российской Федерации;
                  </li>
                  <li>
                     родители либо одинокая(ий) мать (отец), усыновители, отчим (мачеха) многодетной семьи имеют место жительства на территории Московской области не менее 10 лет непрерывно на день подачи заявления о постановке на учет;
                  </li>
                  <li>
                     трое и более детей многодетной семьи имеют место жительства на территории Московской области;
                  </li>
                  <li>
                     члены многодетной семьи не имеют земельного участка площадью 0,06 га и более в собственности, на праве пожизненного наследуемого владения или безвозмездного пользования на территории Московской области;
                  </li>
                  <li>
                     члены многодетной семьи не являются собственниками жилых домов (их частей) на территории Московской области;
                  </li>
                  <li>
                     члены многодетной семьи не производили на территории Российской Федерации отчуждение, а также раздел принадлежащих им на праве собственности земельных участков площадью 0,06 га и более со дня вступления в силу настоящего Закона.
                  </li>
                  <li>
                     члены многодетной семьи не стоят на учете в целях предоставления земельных участков в органе местного самоуправления другого городского округа Московской области.
                  </li>
                  <li>члены многодетной семьи не реализовали право на бесплатное предоставление земельного участка в соответствии с настоящим Законом;</li>
                  <li>члены многодетной семьи не реализовали право на предоставление меры социальной поддержки взамен предоставления земельного участка в соответствии с настоящим Законом;</li>
                  <li>члены многодетной семьи стоят на учете в качестве нуждающихся в жилых помещениях в Московской области.</li>
               </ul>
               <br />
            </div>

            <div className="mt38 borderMain">
               <DocumentContainerDownload type={'PDF'} text='Закон Московской области от 01.06.2011 №73/2011-ОЗ «О бесплатном предоставлении земельных участков многодетным семьям Московской области»' date="06.02.2023" />
            </div>

            <div className={`mt80 text`}>
               <p>
                  Для получения муниципальной услуги «ПОСТАНОВКА МНОГОДЕТНОЙ СЕМЬИ НА УЧЕТ В ЦЕЛЯХ БЕСПЛАТНОГО ПРЕДОСТАВЛЕНИЯ ЗЕМЕЛЬНЫХ УЧАСТКОВ» в рамках реализации Закона Московской области от 01.06.2011 г. № 73/2011-ОЗ «О бесплатном предоставлении земельных участков многодетным семьям Московской области» и Положения о порядке учета в городском округе Химки Московской области многодетных семей в целях бесплатного предоставления в собственность земельных участков, утвержденного Постановлением Администрации ГО Химки от 09.08 2019 № 722 необходимо воспользоваться «Единым порталом государственных и муниципальных услуг (функций)» (http://www.gosuslugi.ru).
               </p>

               <p>
                  Для процедуры регистрации на портале предоставления государственных услуг Вам потребуется паспорт и страховое свидетельство пенсионного страхования (СНИЛС). Активировать свою регистрацию на портале госуслуг можно в МФЦ г. Химки.
               </p>

               <div className="mt38 fw600">Как подать заявку в электронном виде?</div>

               <div className="mt38">
                  1. Войти на портал оказания государственных услуг по адресу: www.gosuslugi.ru.<br />
                  2. Выбрать необходимую услугу.<br />
                  3. Заполнить: форму заявления, указав информацию о себе, почтовый адрес, телефон, текст заявления.<br />
                  4. Выбрать удобное для вас время.<br />
                  5. По окончании внесения данных система выполнит обработку и отправку Вашего запроса в государственные (муниципальные) структуры для прохождения этапов регистрации, проверки заявления и принятия окончательного решения.<br />
               </div>

               <p>
                  Также обратиться в Администрацию ГО Химки для получения муниципальной услуги можно посетив Многофункциональный центр (МФЦ) по адресу: г. Химки, Юбилейный пр-т, д. 67а.
               </p>

               <p>
                  (прием осуществляется по понедельникам с 12-00 по 14-00).
               </p>

               <p>
                  Информацию о филиалах МФЦ (адреса и режим работы) можно получить по телефону: <br />
                  +7 (800) 550-50-30
               </p>

               <p>
                  Консультация по вопросу предоставления муниципальной услуги по телефону: <br />
                  +7 (495) 572-30-97
               </p>


               {/* <div className="mt38 fw600">Для населения:</div>
               <ul className='mt38'>
                  <li>
                     возможность с помощью персонального мобильного устройства лично проверить легальность приобретаемого (получаемого) лекарственного препарата.
                  </li>
               </ul>

               <div className="mt80 pageTitle">Земельные участки многодетным семьям</div>
               <p>
                  Для получения муниципальной услуги «ПОСТАНОВКА МНОГОДЕТНОЙ СЕМЬИ НА УЧЕТ В ЦЕЛЯХ БЕСПЛАТНОГО ПРЕДОСТАВЛЕНИЯ ЗЕМЕЛЬНЫХ УЧАСТКОВ» в рамках реализации Закона Московской области от 01.06.2011 г. № 73/2011-ОЗ «О бесплатном предоставлении земельных участков многодетным семьям Московской области» и Положения о порядке учета в городском округе Химки Московской области многодетных семей в целях бесплатного предоставления в собственность земельных участков, утвержденного Постановлением Администрации ГО Химки от 09.08 2019 № 722 необходимо воспользоваться «Единым порталом государственных и муниципальных услуг (функций)» (http://www.gosuslugi.ru).
               </p>

               <p>
                  Для процедуры регистрации на портале предоставления государственных услуг Вам потребуется паспорт и страховое свидетельство пенсионного страхования (СНИЛС). Активировать свою регистрацию на портале госуслуг можно в МФЦ г. Химки.
               </p> */}
            </div>
         </ContantContainerMain>
      </div>
   )
}
export default Land;