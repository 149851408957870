import React from "react";
import ContantContainerMain from "../../total/ContantContainerMain";
import s from "./ContestsFinancialSupport.module.css";
import { NavLink } from "react-router-dom";
import { ROUTER } from "../../config";

const ContestsFinancialSupport = (props) => {
  return (
    <div>
      <ContantContainerMain>
        <div className={`mt80 breadcrumbs`}>
          <NavLink to={ROUTER.activity.main} className="breadcrumbsFrom">
            Деятельность
          </NavLink>
          <NavLink
            to={ROUTER.activity.smallMediumBusinesses.main}
            className="breadcrumbsFrom"
          >
            {" "}
            / Малое и среднее предпринимательство
          </NavLink>
          <span className="breadcrumbsTo">
            {" "}
            / Конкурсы на предоставления финансовой поддержки
          </span>
        </div>
        <div className={`mt24 pageTitle`}>
          <p>Конкурсы на предоставления</p>
          финансовой поддержки
        </div>

        <div className="text">
          <div className="mt80 pageSubtitle">
            Конкурсы на предоставления финансовой поддержки субъектам малого и
            среднего предпринимательства на право получения субсидии
          </div>
          <p>
            Конкурс по отбору лиц для предоставления финансовой поддержки
            субъектам малого и среднего предпринимательства на право получения
            субсидии за счет средств бюджета городского округа Химки Московской
            области на реализацию мероприятий подпрограммы «Развитие малого и
            среднего предпринимательства» муниципальной программы городского
            округа Химки Московской области «Предпринимательство» в соответствии
            с постановлением Администрации от 19.09.2023 № 1508 «Об утверждении
            Порядка предоставления финансовой поддержки (субсидий) субъектам
            малого и среднего предпринимательства в рамках подпрограммы
            «Развитие малого и среднего предпринимательства» муниципальной
            программы городского округа Химки «Предпринимательство» (далее -
            Порядок) Администрация городского округа Химки Московской области
            (далее - Администрация).Организатор конкурса: Администрация.
          </p>
          <p>
            Адрес: 141400, Московская область, городской округ Химки, ул.
            Московская, д.15, ул. Калинина, д. 4.
          </p>
          <p>
            Прием Заявлений на предоставление финансовой поддержки и выдача
            результатов их рассмотрения осуществляется в электронной форме
            посредством РПГУ (государственная информационная система Московской
            области «Портал государственных и муниципальных услуг (функций)
            Московской области»).
          </p>
          <p>Контактные телефоны:</p>
          <div className="mt38 oval">+7 (495) 573-31-62</div>
          <div className={`mt38 ${s.desription}`}>
            Приёмная МКУ «Малый бизнес Химки».
          </div>
          <p>
            Предметом Конкурса является определение субъектов малого и среднего
            предпринимательства, имеющих право на заключение договора c
            Администрацией о предоставлении субсидии на реализацию мероприятий
            подпрограммы «Развитие малого и среднего предпринимательства»
            муниципальной программы городского округа Химки Московской области
            «Предпринимательство»:
          </p>
          <div className="mt38 fw600">Предмет Конкурса.</div>

          <p>
            Дата начала подачи (приема) заявок участников Конкурса 00:00 часов
            01.10.2024, дата окончания 23:59 часов 30.10.2024 (включительно) по
            московскому времени; В рамках отбора распределяются бюджетные
            ассигнования в сумме 6 000 000 (шести миллионов) рублей;
          </p>
          <p>Период возмещения затрат с 01.01.2024 по день подачи заявки.</p>

          <p>
            <ol>
              <li>
                «Частичная компенсация субъектам малого и среднего
                предпринимательства затрат, связанных с приобретением
                оборудования»;
              </li>
              <li>
                «Частичная компенсация затрат субъектам малого и среднего
                предпринимательства, осуществляющим деятельность в сфере
                социального предпринимательства».
              </li>
            </ol>
          </p>
          <p>
            Конкурсная комиссия - комиссия по отбору заявительных документов
            субъектов МСП на право заключения договора о предоставлении субсидии
            за счет средств бюджета городского округа Химки Московской области.
          </p>
          <p>
            Участник Конкурса – субъект малого или среднего предпринимательства,
            подавший заявление на предоставление финансовой поддержки
            (субсидии).
          </p>
          <div className="mt38 fw600">
            Условия и порядок проведения конкурса.
          </div>

          <p>
            Условия и порядок проведения Конкурса определены Порядком
            предоставления за счет средств бюджета городского округа Химки
            Московской области субсидий субъектам малого и среднего
            предпринимательства на проведение мероприятий подпрограммы «Развитие
            малого и среднего предпринимательства» муниципальной программы
            городского округа Химки Московской области «Предпринимательство»,
            утвержденного
            <a
              href="https://old.admhimki.ru/media/eds/elements/7b16364b-b7b9-43a9-bc8d-42fd7876238c.pdf"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              постановлением Администрации от 19.09.2023 № 1508
            </a>{" "}
            (далее – Порядок).
          </p>
          <p>
            Порядок размещен по
            <a
              href="https://old.admhimki.ru/maloe-i-srednee-predprinimatelstvo/formy-finansovoj-podderzhki-msp-v-moskovskoj-oblasti/"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              ссылке.
            </a>
          </p>
          <div className="mt38 fw600">Принятие решения по итогам Конкурса.</div>
          <p>
            Итоги Конкурса определяются Конкурсной комиссией на основании
            результатов по рассмотрению, поданных участниками конкурса заявок.
          </p>
          <div className="mt38 fw600">
            Заключение договора с победителями Конкурса.
          </div>
          <p>
            Предоставление целевых бюджетных средств городского округа Химки
            Московской области. Договоры о предоставлении субсидии между
            Администрацией и победителями Конкурса должны быть заключены в
            течение 30 (тридцати) календарных дней с даты утверждения протокола
            Конкурсной комиссии о принятии решения о предоставлении субсидии.
          </p>
          <div className={`mt38 ${s.desription}`}>
            Дата публикации: 30.09.2024{" "}
          </div>
        </div>
      </ContantContainerMain>
    </div>
  );
};
export default ContestsFinancialSupport;
