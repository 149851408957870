import DocumentContainerDownloadMini from '../../Components/DocumentContainerDownloadMini';

const information_systems = [
   {
      "Наименование": "Межведомственная система электронного документооборота Московской области",
      "Сайт": "msed.mosreg.ru",
      "Цели и основные задачи, решаемые ИС": "Автоматизация процессов регистрации, хранения и рассылки документации, выдачи и контроля исполнения поручений по связанным с ними резолюциям, а также создания электронного архива документов",
      "Основание для создания и использования ИС":  <DocumentContainerDownloadMini text='Лицензионное соглашение с конечным пользователем' date="06.02.2023" />
   },
   {
      "Наименование": "Единая информационная система оказания государственных и муниципальных услуг Московской области",
      "Сайт": "-",
      "Цели и основные задачи, решаемые ИС": "Предоставление государственных и муниципальных услуг, взаимодействие ОМСУ Администрации с МФЦ, осуществление межведомственного взаимодействия в целях предоставления государственных и муниципальных услуг",
      "Основание для создания и использования ИС":  <DocumentContainerDownloadMini text='Лицензионное соглашение от 21.07.2016 № 35-2016' date="06.02.2023" />
   },
   {
      "Наименование": "Официальный сайт Администрации городского округа Химки Московской области",
      "Сайт": "admhimki.ru",
      "Цели и основные задачи, решаемые ИС": "Информирование о деятельности Администрации городского округа Химки Московской области",
      "Основание для создания и использования ИС":  <DocumentContainerDownloadMini text='Постановление Администрации городского округа Химки Московской области от 19.05.2015 № 528' date="06.02.2023" />
   },
   {
      "Наименование": "Официальный сайт Управления по образованию Администрации городского округа Химки Московской области",
      "Сайт": "himki-edu.ru",
      "Цели и основные задачи, решаемые ИС": "Размещение в открытом доступе информации об Управлении по образованию Администрации городского округа Химки Московской области, его деятельности, а также деятельности подведомственных Управлению организаций. Информирование законных представителей несовершеннолетних граждан, других лиц о функционировании системы образования округа Химки Московской области",
      "Основание для создания и использования ИС":  <DocumentContainerDownloadMini text='Приказ Управления по образованию Администрации городского округа Химки Московской области' date="06.02.2023" />
   },
   {
      "Наименование": "Официальный сайт Муниципального бюджетного учреждения «Комбинат школьного питания»",
      "Сайт": "khp-himki.ru ",
      "Цели и основные задачи, решаемые ИС": "Приказ «О создании и обработке официального сайта учреждения» от 01.03.2017 № 033/1-0/17",
      "Основание для создания и использования ИС":  <DocumentContainerDownloadMini text='Приказ «О создании и обработке официального сайта учреждения»' date="06.02.2023" />
   },
   {
      "Наименование": "Официальный сайт Автономного учреждения городского округа Химки «Центр аварийно-технического обслуживания и ремонта»",
      "Сайт": "augohcato.ru",
      "Цели и основные задачи, решаемые ИС": "Предоставление информации о деятельности учреждения",
      "Основание для создания и использования ИС":  <DocumentContainerDownloadMini text='Федеральный закон Российской Федерации о 03.11.2006 № 174-ФЗ' date="06.02.2023" />
   },

]

export default information_systems;