import React from 'react';
import { NavLink } from 'react-router-dom';
import { ROUTER } from '../../config';
import ContantContainerMain from '../../total/ContantContainerMain';
import arrow from '../../assets/icons/arrow_btn_link.svg';
import s from './InformationForReview.module.css';
import DocumentContainerDownload from '../../Components/DocumentContainerDownload';
import btnArr from '../../assets/icons/government_link_button.svg';

const InformationForReview = (props) => {
   return (
      <div>
         <ContantContainerMain>
            <div className={`mt80 breadcrumbs`}>
               <NavLink to={ROUTER.citizensAppeals.main} className='breadcrumbsFrom'>Работа с обращениями граждан</NavLink>
               <span className='breadcrumbsTo'> / Информация для ознакомления желающим отправить обращение в форме электронного документа</span>
            </div>
            <div className={`mt24 pageTitle`}>
               Информация для ознакомления <br /> желающим отправить обращение <br /> в форме электронного документа
            </div>

            <div className="mt80 pageSubtitle">
               Информация для ознакомления желающим отправить <br /> обращение в форме электронного документа
            </div>


            <div className={`mt80 text`}>
               <p>
                  Пожалуйста, прежде чем отправить обращение в форме электронного документа, внимательно ознакомьтесь
                  с полномочиями и сферой деятельности Администрации городского округа Химки Московской области (далее – Администрация), а также со следующей информацией.
               </p>
            </div>

            <div className="mt38 borderMain">
               <DocumentContainerDownload type={'PDF'} text={'Устав городского округа Химки московской области'} date={'06.02.2023 '} />
            </div>

            <div className={`mt80 text`}>
               <ol className='mt38'>
                  <li>
                     Обращения, направленные в форме электронного документа через официальный сайт, поступают на рассмотрение в Администрацию и рассматриваются работниками Администрации — уполномоченными на то лицами.
                  </li>
                  <li>
                     Перед отправкой обращения в форме электронного документа необходимо его написать.
                  </li>
                  <li>
                     В обязательном порядке указав в электронной анкете:
                  </li>
                  <li>
                     Либо наименование «Администрация городского округа Химки Московской области», фамилию, имя, отчество соответствующего лица, должность соответствующего лица, которому Вы направляете обращение;
                  </li>
                  <li>
                     Свою фамилию, имя, отчество (последнее — при наличии);
                  </li>
                  <li>
                     Адрес электронной почты, по которому должны быть направлены ответ, уведомление о переадресации обращения;
                  </li>
                  <li>
                     Изложите в поле ввода текста обращения суть предложения, заявления, жалобы.
                  </li>
                  <li>
                     Ответ на Ваше обращение либо уведомление о его переадресации направляется в форме электронного документа по адресу электронной почты (e-mail), указанному Вами в обращении.
                  </li>
                  <li>
                     В предназначенном для обязательного заполнения поле ввода текста обращения Вы излагаете суть предложения, заявления или жалобы в соответствии со ст. 7 Федерального закона от 2 мая 2006 года № 59-ФЗ «О порядке рассмотрения обращений граждан Российской Федерации».
                  </li>
                  <p>
                     В случае, если текст Вашего обращения не позволяет определить суть предложения, заявления или жалобы, ответ на обращение не дается, и оно не подлежит направлению на рассмотрение в государственный орган, орган местного самоуправления или должностному лицу в соответствии с их компетенцией, о чем Вам будет сообщено в течение семи дней со дня регистрации обращения.
                  </p>
                  <li className="mt38">
                     В случае необходимости в подтверждение своих доводов Вы вправе приложить к обращению необходимые документы и материалы в электронной форме.
                  </li>
                  <p>
                     Приложить необходимые документы и материалы в электронной форме Вы можете в любой последовательности (за исключением аудио- и видеофайлов).
                  </p>

                  <p>
                     Информируем Вас, что передача файла(ов) вложения на почтовый сервер зависит от пропускной способности сети «Интернет», а получение — от объёма обрабатываемых почтовым сервером переданных файлов.
                  </p>

                  <p>
                     При подключении Вашего оборудования к сети «Интернет» по выделенным каналам связи с использованием технологий ADSL, 3G, 4G, WiFi и иных технологий, обеспечивающих аналогичные скорости передачи данных в сети «Интернет», передача и обработка файла(ов) с суммарным размером:
                  </p>

                  <ul className='mt38'>
                     <li>до 5 Мб осуществляется, как правило, без задержки во времени;</li>
                     <li>от 5 Мб до 10 Мб может осуществляться с задержкой во времени;</li>
                     <li>свыше 10 Мб может быть не осуществлена.</li>
                  </ul>

                  <li className="mt38">
                     Если в направленном Вами тексте в форме электронного документа, содержащемся в поле ввода текста обращения в форме электронного документа, не изложено предложение, заявление или жалоба, а только ссылка на приложение (файл вложение) либо на контент интернет-сайта, то в ответе разъясняется порядок его рассмотрения, установленный Федеральным законом от 2 мая 2006 года № 59-ФЗ «О порядке рассмотрения обращений граждан Российской Федерации».
                  </li>
                  <li>
                     Обращаем Ваше внимание на порядок рассмотрения отдельных обращений, предусмотренный ст. 11 Федерального закона от 2 мая 2006 года № 59-ФЗ «О порядке рассмотрения обращений граждан Российской Федерации».
                  </li>
                  <li>
                     При направлении Вами обращений, касающихся обжалования судебных решений, необходимо иметь в виду следующее. Согласно Конституции Российской Федерации правосудие в России осуществляется только судом. Органы судебной власти самостоятельны и действуют независимо от законодательной и исполнительной властей. Решения судебных органов обжалуются в установленном законом процессуальном порядке.
                  </li>
                  <li>
                     Информация о персональных данных авторов обращений, направленных в форме электронного документа, сведения, содержащиеся в обращениях авторов, а также сведения, касающиеся частной жизни авторов, хранятся и обрабатываются с соблюдением требований российского законодательства.
                  </li>
                  <li>
                     Предусмотрена возможность просмотра сайта на различных устройствах — от смартфонов и планшетов до широкоформатных мониторов. А при ширине окна браузера выше 1570 пикселей Вы можете читать материалы сайта, сохраняя при этом доступ к навигации по странице, с которой Вы перешли на выбранную публикацию.
                  </li>
               </ol>

               <NavLink to={ROUTER.citizensAppeals.writeAdministration} className={`btnY ${s.btn}`}>Написать письмо <img src={btnArr} alt="" /></NavLink>

               <div className="mt38 borderMain">
                  <div className={s.deacription}>Адрес для отправки обращений в письменной форме в Администрацию городского округа Химки Московской области обычной почтой: 141 400, Московская обл., г. Химки, ул. Московская, д. 15.</div>
               </div>

            </div>
         </ContantContainerMain>
      </div>
   )
}
export default InformationForReview;