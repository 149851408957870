import React from 'react';
import s from './AllRussianDayReceptionCitizens.module.css';

import { ROUTER } from '../../config';
import { NavLink } from 'react-router-dom';

import ContantContainerMain from '../../total/ContantContainerMain';

const AllRussianDayReceptionCitizens = (props) => {
   return (
      <div>
         <ContantContainerMain>
            <div className={`mt80 breadcrumbs`}>
               <NavLink to={ROUTER.citizensAppeals.main} className='breadcrumbsFrom'>Обращения граждан</NavLink>
               <span className='breadcrumbsTo'> / Общероссийский день приёма граждан в День Конституции Российской Федерации 12 декабря</span>
            </div>
            <div className="mt24 pageTitle">
               Общероссийский день приёма <br />
               граждан в День Конституции <br />
               Российской Федерации 12 <br />
               декабря
            </div>

            <div className="text">
               <div className="mt80 pageSubtitle">О проведении общероссийского дня приёма граждан в день конституции российской федерации 12 декабря 2019 года</div>
               <p>
                  В соответствии с поручением Президента Российской Федерации ежегодно, начиная с 12 декабря 2013 года, в День Конституции Российской Федерации проводится общероссийский день приема граждан с 12 часов 00 минут до 20 часов 00 минут по местному времени в Приемной Президента Российской Федерации по приему граждан в городе Москва, приемных Президента Российской Федерации в федеральных округах и в административных центрах субъектов Российской Федерации (далее — приемные Президента Российской Федерации), в федеральных государственных органах и в соответствующих территориальных органах, в исполнительных органах государственной власти субъектов Российской Федерации (далее — государственные органы) и в органах местного самоуправления.
               </p>
               <p>
                  С 12 часов 00 минут до 20 часов 00 минут по местному времени проводят личный прием заявителей, пришедших в соответствующие приемные Президента Российской Федерации, государственные органы или органы местного самоуправления, уполномоченные лица данных органов и обеспечивают с согласия заявителей личное обращение в режиме видео-конференц-связи, видеосвязи, аудиосвязи или иных видов связи к уполномоченным лицам иных органов, в компетенцию которых входит решение поставленных в устных обращениях вопросов. Личный прием проводится в порядке живой очереди при предоставлении документа, удостоверяющего личность (паспорта).
               </p>
               <p>
                  В случае если уполномоченные лица органов, осуществляющие личный прием заявителей, не обеспечили, с учетом часовых зон, возможность личного обращения заявителей в режиме видео-конференц-связи, видеосвязи, аудиосвязи или иных видов связи к уполномоченным лицам органов, в компетенцию которых входит решение поставленных в устных обращениях вопросов, то в течение 7 рабочих дней после общероссийского дня приема граждан или в иные удобные для данных заявителей сроки будет обеспечена возможность личного обращения к соответствующим уполномоченным лицам. О времени, дате и месте проведения приема в режиме видео-конференц-связи, видеосвязи, аудиосвязи или иных видов связи данные заявители информируются в течение 3 рабочих дней после общероссийского дня прием а граждан.
               </p>
               <p>
                  По решению соответствующих государственных органов и органов местного самоуправления может осуществляться предварительная запись заявителей на личный прием в общероссийский день приема граждан.
               </p>
               <p>
                  На территории городского округа Химки Московской области приём граждан будем проходить в Общественной приемной Администрации городского округа по адресу: г. Химки, ул. Московская, дом 15, кабинет 12.
               </p>
               <p>
                  Дополнительную информацию о приёме можно уточнить по телефону с 9.00 до 18.00
               </p>

               <div className="mt38 oval">+7 (495) 572-73-38</div>
               <div className={`mt38 ${s.desription}`}>Дата публикации: 03.12.2019</div>


               <div className="mt120">
                  <div className="pageSubtitle">Информация о проведении общероссийского дня приёма граждан в день конституции российской федерации 12 декабря 2018 года</div>
               </div>

               <p>
                  В соответствии с поручением Президента Российской Федерации ежегодно, начиная с 12 декабря 2013 года, в День Конституции Российской Федерации проводится общероссийский день приема граждан с 12 часов 00 минут до 20 часов 00 минут по местному времени в Приемной Президента Российской Федерации по приему граждан в городе Москве, приемных Президента Российской Федерации в федеральных округах и в административных центрах субъектов Российской Федерации (далее — приемные Президента Российской Федерации), в федеральных органах исполнительной власти и в соответствующих территориальных органах, в федеральных государственных органах и в соответствующих территориальных органах, в исполнительных органах государственной власти субъектов Российской Федерации (далее — государственные органы) и в органах местного самоуправления.
               </p>

               <p>
                  С 12 часов 00 минут до 20 часов 00 минут по местному времени проводят личный прием заявителей, пришедших в соответствующие приемные Президента Российской Федерации, государственные органы или органы местного самоуправления, уполномоченные лица данных органов и обеспечивают с согласия заявителей личное обращение в режиме видео-конференц-связи, видеосвязи, аудиосвязи или иных видов связи к уполномоченным лицам иных органов, в компетенцию которых входит решение поставленных в устных обращениях вопросов. Личный прием проводится в порядке живой очереди при предоставлении документа, удостоверяющего личность (паспорта).
               </p>

               <p>
                  В случае если уполномоченные лица органов, осуществляющие личный прием заявителей, не обеспечили, с учетом часовых зон, возможность личного обращения заявителей в режиме видео-конференц-связи, видеосвязи, аудиосвязи или иных видов связи к уполномоченным лицам органов, в компетенцию которых входит решение поставленных в устных обращениях вопросов, то в течение 7 рабочих дней после общероссийского дня приема граждан или в иные удобные для данных заявителей сроки будет обеспечена возможность личного обращения к соответствующим уполномоченным лицам. О времени, дате и месте проведения приема в режиме видео-конференц-связи, видеосвязи, аудиосвязи или иных видов связи данные заявители информируются в течение 3 рабочих дней после общероссийского дня приема граждан.
               </p>

               <p>
                  По решению соответствующих государственных органов и органов местного самоуправления может осуществляться предварительная запись заявителей на личный прием в общероссийский день приема граждан.
               </p>

               <p>
                  Информация об адресах проведения 12 декабря 2018 года приема заявителей размещена на официальном сайте Президента Российской Федерации в сети Интернет на странице «Личный прием» раздела «Отправить письмо» (http://letters.kremlin.ru/receptions), а также на официальных сайтах соответствующих государственных органов и органов местного самоуправления в сети Интернет.
               </p>

               <p>
                  На территории городского округа Химки Московской области приём граждан будем проходить в Общественной приемной Администрации городского округа по адресу:г. Химки, ул. Московская, дом 15, кабинет 12 с 10 часов 00 минут до 20 часов 00 минут по местному времени. Дополнительную информацию о приёме можно уточнить по телефону с 9.00 до 18.00
               </p>
               <div className="mt38 oval">+7 (495) 572-73-38</div>
               <div className={`mt38 ${s.desription}`}>Дата публикации: 04.12.2019 </div>
            </div>
         </ContantContainerMain>
      </div>
   )
}
export default AllRussianDayReceptionCitizens;